




































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab4Introduction',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: '',
        part2: '',
        part3: '',
        part4: '',
        language: 'en',
      },
      questions: [
        {
          en: '1) What are acids, and how do they vary in strength?',
          fr: '1) Que sont les acides et comment est-ce qu’ils changent de fort à faible?',
          inputName: 'part1',
        },
        {en: '2) What is a base?', fr: '2) Que sont des bases?', inputName: 'part2'},
        {
          en: '3) What is an acid base reaction?',
          fr: '3) C’est quoi, une réaction acido-basique?',
          inputName: 'part3',
        },
        {en: '4) What is an indicator?', fr: '4) C’est quoi un indicateur?', inputName: 'part4'},
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
